import * as React from "react"
import PostCategorys from "./PostCategorys"

const BlogFooter = (props) => {
    return (
            <div className="post-footer blog-footer">
                <PostCategorys info={props.postInfo}/>
                <div>
                    <span>
                        {new Date(props.postInfo.updated_at ?? props.postInfo.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
                    </span>
                </div>
            </div>
    )
}

export default BlogFooter