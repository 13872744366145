export class StructuredDataHelper {

    post = {}
    
    constructor(postData)
    {
        this.domain = `https://www.customessaymeister.com`
        this.siteName = `CustomEssayMeister.com`
        this.post = postData
        this.BreadcrumbList = {}
        this.product = {}
    }
    getBreadcrumbs ({
        itemListElements = []
        }) {
        this.BreadcrumbList = {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
        
            itemListElement: itemListElements.map(
                ({ position, item, name }) => ({
                '@type': 'ListItem',
                position,
                item: { '@id': item, name, '@type': 'Thing' },
                }),
            )
        };
      
        return JSON.stringify(this.BreadcrumbList, null, 2);
    };
    getProductJsonLd ({
        name,
        productName,
        images = [],
        description,
        sku,
        gtin12,
        gtin,
        gtin8,
        gtin13,
        gtin14,
        mpn,
        brand,
        reviews = [],
        aggregateRating,
        offers
    }) {
        this.product = {
          '@context': 'https://schema.org',
          '@type': 'Product',
          name: name ?? productName,
          image: images,
          sku,
          gtin,
          gtin8,
          gtin12,
          gtin13,
          gtin14,
          mpn,
          brand: brand ? { '@type': 'Brand', name: brand } : undefined,
          description,
          review: reviews.map(({ reviewRating, ...review }) => ({
            '@type': 'Review',
            ...review,
            reviewRating: { '@type': 'Rating', ...reviewRating },
          })),
          aggregateRating: aggregateRating
            ? { '@type': 'AggregateRating', ...aggregateRating }
            : undefined,
          offers
        };
      
        return JSON.stringify(this.product, null, 2);
    }
    get() 
    {
        return `
            {
                "@context": "https://schema.org",
                "@graph": [
                    {
                        "@type": "WebSite",
                        "@id": "${this.domain}#website",
                        "url": "${this.domain}",
                        "name": "${this.siteName}",
                        "description": "Best Online Writing Service",
                        "potentialAction": [
                            {
                                "@type": "SearchAction",
                                "target": {
                                    "@type": "EntryPoint",
                                    "urlTemplate": "${this.domain}/?search={search_term_string}"
                                },
                                "query-input": "required name=search_term_string"
                            }
                        ],
                        "inLanguage": "en-US"
                    },
                    {
                        "@type": "WebPage",
                        "@id": "${this.post.url}#webpage",
                        "url": "${this.post.url}",
                        "name": "${this.post.post_meta_title}",
                        "isPartOf": {
                            "@id": "${this.domain}/#website"
                        },
                        "datePublished": "${this.post.created_at}",
                        "dateModified": "${this.post.updated_at}",
                        "breadcrumb": {
                            "@id": "${this.post.url}"
                        },
                        "inLanguage": "en-US",
                        "potentialAction": [
                            {
                                "@type": "ReadAction",
                                "target": [
                                    "${this.post.url}"
                                ]
                            }
                        ]
                    },
                    {    
                        "@type": "BreadcrumbList",
                        "@id": "${this.post.url}#breadcrumb",
                        "itemListElement": [
                            {
                                "@type": "ListItem",
                                "position": 1,
                                "item": {
                                    "@id": "${this.domain}",
                                    "name": "${this.siteName}",
                                }
                            },
                            {
                                "@type": "ListItem",
                                "position": 2,
                                "item": {
                                    "@id": "${this.domain}/free-essays",
                                    "name": "✔️ Free Essays",
                                }
                            },
                            {
                                "@type": "ListItem",
                                "position": 3,
                                "item": {
                                    "@id": "${this.post.id}",
                                    "name": "⭐️⭐️⭐️${this.post.post_title}",
                                }
                            }
                        ]
                    }
                ]
            }
        `
    }

}