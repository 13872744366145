import React from "react"

const TheCategories = ({categories, title, showFilter, hideFilter, url, currentCategories, currentSubCategories}) => {
    const sortArray = (a, b) => {
        if (a.title < b.title) {return -1;}
        if (a.title > b.title) {return 1;}
        return 0;
    }
    categories.sort(sortArray).map((item) => {
        const sortedSubcategories = item.categories.sort(sortArray)
        return {
            ...item,
            categories: sortedSubcategories
        }
    })
    const toggleSublist = (index) => {
        const id = index.replace(/ /g,'_')
        const item = document.getElementById(`${id}`)
        item.classList.toggle('category-filter__list_item--active')
    }
    return (
        <div
            className={showFilter ? 'category-filter category-filter__active' : 'category-filter'}
        >
            <header className="category-filter__header">
                <span
                    className="category-filter__header-close"
                    role={'presentation'}
                    onClick={hideFilter}
                ></span>
    
                <div className="category-filter__title">
                    { title }
                </div>
            </header>
    
            <section className="category-filter__body">
                <ul className="category-filter__list">
                    {categories.map((category) => {
                        return (
                            <li
                                id={category.title.replace(/ /g,'_')}
                                key={category.id}
                                className={categoriesClasses(category)}
                                role="presentation"
                                onClick={() => toggleSublist(category.title)}
                            >
                                {
                                    <div>
                                        <span>
                                            { category.title }
                                            <i>
                                                { category.posts_count }
                                            </i>
                                        </span>
            
                                        {
                                            category.categories.length &&
                                                <ul
                                                className="category-filter__list_item_sublist"
                                            >
                                                {category.categories.map((subcategory) => {
                                                    return (
                                                        subcategory.posts_count > 0 &&
                                                        <li
                                                            key={subcategory.id}
                                                            className='category-filter__list_item_sublist_item'
                                                        >
                                                            <a 
                                                                href={`/${url}/${category.slug}/${subcategory.slug}`} 
                                                                className={currentSubCategories === subcategory.title ? 'category-filter__category-name active': 'category-filter__category-name'}
                                                                title={subcategory.title}
                                                            >
                                                                <span>{ subcategory.title }</span> <i>{ subcategory.posts_count }</i>
                                                            </a>

                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        }
                                    </div>
                                }
                            </li>
                        )
                    })}
                </ul>
            </section>
        </div>
    )

    function categoriesClasses (category) {
        const classes = {
            'category-filter__list_item': !!category.categories,
            'category-filter__list_item_sublist_item': !category.categories,
            'category-filter__list_item--active': category.title === currentCategories
        }
        const arr = Object.entries(classes).map((item) => (`${item[1] ? item[0] : ''}`))
        return arr.join(' ')
    }
}

export default TheCategories