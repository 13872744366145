import React, {useState} from "react"
import Layout from "../components/layout"
import PostCard from "../components/posts/PostCard"
import BlogFooter from "../components/posts/BlogFooter"
import Breadcrumbs from "../components/Breadcrumbs"
import Pagination from "../components/Pagination"
import Seo from "@components/Seo.js"
import TheCategories from "../components/TheCategories"
import categories from "../content/blogCategories.json"
import Filter from "../components/ImagesComponents/Filter"

import { StructuredDataHelper } from "@helpers/structuredDataHelper.js"

const Blog = ({pageContext}) => {
    const structuredData = new StructuredDataHelper(pageContext)
    const posts = pageContext.content
    const pages = pageContext.pages
    const categoriesLink = pageContext.categoriesLink
    const steps = [
        {
            name: 'Blog',
            path: '/blog'
        }
    ]
    const category = pageContext.category || ''
    const subcategory = pageContext.subcategory || ''

    const [showFilter, setShowFilter] = useState(false)
    
    const hideFilter = () => {
        setShowFilter(!showFilter)
    }
    const itemListElements = [
        {
            position: 1,
            name: 'CustomEssayMeister',
            item: 'https://www.customessaymeister.com/'
        },
        {
            position: 2,
            name: '✔️ Blog',
            item: 'https://www.customessaymeister.com/blog'
        }
    ]
    const seoBlog = {
        title: `Essay Writing Blog`,
        description: `Get great writing tips, study guides, and writing tutorials on our essay writing blog. Page № ${pageContext.counter}`,
        keywords: `writing help, tutorials, write my paper, help with essay`,
        // robots: pageContext.counter === 1 ? 'index, follow' : 'noindex, follow',
        robots: 'noindex, follow',
        micromarking: structuredData.getBreadcrumbs({ itemListElements })
    }
    const seoCategory = {
        title: `${category}, ${subcategory}`,
        description: `${category} writing guides. ${subcategory} assignment help with tutorials. Page № ${pageContext.counter}`,
        keywords: `${category} ${subcategory}`,
        // robots: pageContext.counter === 1 ? 'index, follow' : 'noindex, follow',
        robots: 'noindex, follow',
        micromarking: structuredData.getBreadcrumbs({ itemListElements })
    }
    const pageData = subcategory ? {...seoCategory} : {...seoBlog}
    return (
        <Layout>
            <Seo {...pageData} />
            <div className="blog-intro">
                <div className="wrapper">
                    <Breadcrumbs steps={steps}/>
                    <h1 className="page-intro__title">
                        {subcategory ? subcategory : 'Blog'}
                        <span
                            className="filterBtn button button-primary"
                            role={'presentation'}
                            onClick={hideFilter}
                        >
                            <Filter width={30} height={30} fill='white' />
                        </span>                    
                    </h1>
                </div>
            </div>
            <div className="blog-content wrapper">
                <div className="blog">
                    {posts.map(item => 
                        <PostCard showImage key={item.id} post={item} name="/blog" footer={<BlogFooter postInfo={item}/>}/>
                        )
                    }
                </div>
                <div className="blog-categories">
                    <TheCategories 
                        categories={categories}
                        currentCategories={category}
                        currentSubCategories={subcategory}
                        url="blog/category"
                        title="BLOG CATEGORIES"
                        showFilter={showFilter}
                        hideFilter={hideFilter}
                    />
                </div>
                
                <Pagination
                    pageName={categoriesLink ? `blog/${categoriesLink}` : 'blog'}
                    pages={pages} count={pageContext.counter}
                />
            </div>
        </Layout>
    )
}

export default Blog
