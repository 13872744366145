import * as React from "react"

const PostCategorys = (props) => {
    return (
        <div className="categorys">
            {props.info.category_group_title &&
                <span className="category">
                    {props.info.category_group_title}
                </span>
            }
            {props.info.category_title &&
                <span className="subcategory">
                    <span className="category">
                        {props.info.category_title}
                    </span> 
                </span>
            }
        </div>
    )
}

export default PostCategorys